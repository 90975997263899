<template>
  <div class="container-fluid text-left mt-3">
    <div class="card nw-card">
      <div class="card-header nw-card-header">
        <h4>Session Summary</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <label class="control-label">Patient ID</label>
            <div class="input-group">
              <input class="form-control" type="text" v-model="participantID"/>
              <div class="input-group-append"><button class="btn btn-outline-secondary" @click="search()">Search</button></div>
            </div>
          </div>
        </div>
        <errors :resp="nc.resp" :retval="nc.retval" class="mt-2"></errors>
        <div class="row mt-2">
          <div class="col-6 text-left">
            <button class="btn btn-outline-secondary" @click="back()"><i class="fa fa-chevron-left"></i></button>
          </div>
          <div class="col-6 text-right">
            {{ skip }}
            <button class="btn btn-outline-secondary" @click="next()"><i class="fa fa-chevron-right"></i></button>
          </div>
        </div>

        <table class="table table-hover table-sm mt-2">
          <thead><tr><th>Patient ID</th><th>Initials</th><th>Start</th><th>End</th><th>Hours</th><th>Interventions</th></tr></thead>
          <tbody style="cursor:pointer">
            <tr v-for="item in arr" @click="select(item.oid)">
              <td>{{ item.participantID}}</td>
              <td>{{ item.initials}}</td>
              <td>{{ item.startDate.toLocaleDateString()}} {{ item.startDate.toLocaleTimeString()}}</td>
              <td>{{ item.endDate.toLocaleDateString()}} {{ item.endDate.toLocaleTimeString()}}</td>
              <td>{{ item.duration }}</td>
              <td>{{ item.interventions}}</td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import NetClient from '@/util/netclient.js'
import Errors from '@/components/Errors'

export default {
  components: { Errors },
  mounted () {
      this.search()
  },
  data () {
    return {
      participantID: '',
      oldPid: '',
      skip: 0,
      limit: 10,
      nc: NetClient,
      arr: []
    }
  },
  methods: {
    select (_oid) {
      NetClient.doRequest('rest/session', { action: 'selectPatient', patientID: _oid }).then(msg => {
        if('OK' == msg.retval) this.$router.push({ name: 'SleepSummary'})
      })
    },
    search () {
      if(this.oldPid != this.participantID)
      {
        this.skip = 0
        this.oldPid = this.participantID
      }

      NetClient.doRequest('rest/patient', { action: 'searchSleep', skip: this.skip, limit: this.limit, participantID: this.participantID }).then(msg => {
        if(typeof msg.data != 'undefined')
        {
          var ii
          for(ii=0;ii<msg.data.length;ii++)
          {
            msg.data[ii].startDate = new Date(  msg.data[ii].startTime)
            msg.data[ii].endDate = new Date(  msg.data[ii].endTime)
          }
          this.arr = msg.data
        } else this.arr = []
      })
    },
    sessionone () {
      this.$router.push('/participant/summary')
    },
    next () {
      this.skip = this.skip + this.limit
      this.search()
    },
    back () {
      this.skip = this.skip - this.limit
      if(this.skip < 0) this.skip = 0
      this.search()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
